.detailscar {
  width: 50%;
  height: 500px;
  border-radius: 5px;
  object-fit: cover;
}

/* .reviews {
  width: 1000px;
  height: 200px;
  border-radius: 10px;
  margin:10px;
  padding: 10px;
} */

.carousel .slide .reviews {
  width: 30%;
  height: auto;
  margin: 0 auto;
}