@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
*{
    padding:0;
    margin:0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 225);
}
a{
    text-decoration: none;
}

li{
    list-style: none;
}
.section{
    padding: 4rem 0 2rem; 
}

.container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon{
    font-size:2rem;
    cursor: pointer;
}

.flex{
    display: flex;
    align-items:center;
}

.grid{
    display: grid;
    align-items: center;
}

.btn{
    padding: .6rem 1.5rem;
    background: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    background: var(--gradientColor);
    border-radius: 3rem;
    border: none;
    outline: none;
    cursor: pointer
}

.btn a{
    color: hsl(0, 0%, 100%);
    color: var(--whiteColor);
    font-weight: 500;
}

.btn:hover{
    background: hsl(187, 85%, 43%);
    background: var(--SecondaryColor);
}

img, video{
    width: 100%;
    height: auto;
}

input{
    font-size: 100%;
}

body{
    background: rgb(240, 240, 246);
    background: var(--bodyColor);
}

.blocker {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ' ';
    background: rgba(0,0,0,.5);
  }
.main {
  width: 100%;
}

.titler {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: var(--textColor);
  margin: 1rem 0;
  z-index: 2;
}

@media screen and (max-width: 500px) {
  .titler {
    margin-top: 2rem;
  }
}
.secContent {
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.Card {
  height: 100%;
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
  border-radius: 10px;
  align-items: center;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  overflow: hidden;
}
.Card:hover {
  background: var(--cardHover);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.imageDiv {
  height: 180px;
  width: 100%;
  overflow: hidden;
}
.imageDiv img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 2s ease;
}
.imageDiv:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.cardInfo {
  padding: 1rem;
}

.titles {
  margin-left: 12px;
}

.dest {
  color: var(--blackColor);
  font-size: 1.5rem;
  font-weight: 800;
  height:4.6rem;
}
.continent {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.icon {
  color: var(--textColor);
  font-size: 18px;
}
.state, .season {
  color: var(--textColor);
  font-weight: 400;
  font-size: 14px;
}

.season {
  padding-right: 20px;
}
.smallTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardInfo .meta {
  width: 100%;
  /* flex-direction:column; */
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0;
  border-top: 1.5px solid var(--greyText);
  border-bottom: 1.5px solid var(--greyText);
}
.meta .grade {
  color: var(--textColor);
  max-width: 100px;
  line-height: 20px;
  display: flex;
  justify-content: center;
}
.meta .grade small {
  background: var(--gradientColor);
  border-radius: 1rem;
  color: var(--whiteColor);
  padding: 1px 10px;
  font-size: 10px;
}
.meta .duration {
  font-size: 1.25rem;
  color: var(--blackColor);
}
.meta .price {
  font-size: 1.25rem;
  color: var(--blackColor);
}
 .desc {
  font-size: 13.5px;
  color: var(--textColor);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding-left: 15px;
  
}


 .desc p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

 .desc .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: -2px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.desc .btn .icon {
  align-self: center;
  font-size: 18px;
  color: var(--whiteColor);
}

@media screen and (min-width: 625px) {
  .secContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .secContent .Card {
    height: auto;
  }
}

@media screen and (min-width: 1000px) {
  .secContent {
    grid-template-columns: repeat(3, 1fr);
  }
  .secContent .Card {
    height: auto;
  }
} 

@media screen and (max-width: 1000px) {
  .meta {
    flex-direction: column;
  }

  .desc {
    text-align: center;
  }

  .desc .btn {
    margin: auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 320px) {
  .cardInfo > .titles > .dest {
    height: 6.5rem;
  }
}
.reviewPage {
  /* background-color: white; */
  padding: 1rem;
  /* border: 1px solid black; */
}
.singleReview {
  display: grid;
  border-radius: 10px;
  align-items: center;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  overflow: hidden;
  padding: 1.5rem 1.75rem;
  margin: 0.75rem;
}

.reviewTitle {
  font-weight: 800;
  /* font-size: 1.25rem; */
  color: var(--textColor);

}

.reviewAuthor {
  font-size: 0.85rem;
  margin: 0.5rem 0rem;
}

.reviewHead {
  padding: 0.5rem 1rem;
  /* text-decoration: underline; */
}

.writeInput {
  border: none;
  width: 100%;
  border-bottom: 1px solid gray;
  background-color: var(--cardBG);
  margin: 0.5rem;
}

.writeInput:focus {
    outline-style:none
  }

.writeText {
  width: 70vw;
  height: 10vh;
  font-family: inherit;
  font-size: 20px;
  border-left: 1px solid gray;
}

.authorip {
  border: none;
  border-bottom: 1px solid gray;
  background-color: var(--cardBG);
  margin: 0.5rem;
  font-size:0.8rem;
}

.authorip:focus{
  outline-style: none;
}

.writeSubmit {
  /* position: relative; */
  /* right: 7%; */
  color: white;
  background-color: teal;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.reviewImage {
  width: 350px;
  height:200px;
}

.reviewImgCont {
 display:flex;
 flex-direction: row;
 flex-wrap: wrap;
}

.singleImgCont{
  margin:5px;
  position: relative;
}
.deletePhoto {
  position: absolute;
  top:0;
  right:5px;
  font-weight:bold;
  padding: 2px;
}
.detailscar {
  width: 50%;
  height: 500px;
  border-radius: 5px;
  object-fit: cover;
}

/* .reviews {
  width: 1000px;
  height: 200px;
  border-radius: 10px;
  margin:10px;
  padding: 10px;
} */

.carousel .slide .reviews {
  width: 30%;
  height: auto;
  margin: 0 auto;
}
:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 225);
}

.header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: hsl(0, 0%, 93%);
    background-color: var(--whiteColorDeam);
    width: 100%;
    padding: 1rem;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
}

.logo{
    color: black;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.logo .icon{
    font-size: 25px;
    color: hsl(199, 100%, 33%);
    color: var(--PrimaryColor);
    -webkit-transform: translate(5px);
            transform: translate(5px);
    margin:1rem;
    height: 40px;
    width: auto;
}

a .btn {
    color:hsl(0, 0%, 100%);
    color:var(--whiteColor);
    font-weight: 500;
}

@media screen and (max-width: 400px) {
    .logo {
        font-size: 10px;
    }
}

@media screen and (max-width: 911px) {
    .navBar{
        position:absolute;
        background:white;
        height: -webkit-max-content;
        height: max-content;
        width:50%;
        padding: 1rem;
        border-radius: 1rem;
        display: none;
        top: 6rem;
        left: 50%;
        /* transform: translate(-50%); */
        z-index:2000;
        box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
        transition: .1s ease-in-out;
    }

    .activeNavBar{
        display:block;
        /* top:6rem; */
    }

    .navLists{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: auto;
        padding: .5rem 0;
    }
    .navItem{
        padding: .5rem 0;
    }

    .closeNavBar{
        display: block;
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        color: hsl(199, 100%, 33%);
        color: var(--PrimaryColor);
    }
    .closeNavBar:hover{
        color: hsl(187, 85%, 43%);
        color: var(--SecondaryColor);
    }

    .openNavBar .icon{
        display: block;
        font-size: 25px;
        color: black;
    }

    .openNavBar .icon:hover{
        color: hsl(199, 100%, 33%);
        color: var(--PrimaryColor);
    }

    
.btn{
    background-color: hsl(199, 100%, 33%);
    background-color: var(--PrimaryColor);
    margin-top: 1rem;
}

}

.navLink{
    font-size: .9rem;
    font-weight: 600;
    color: black;
}
.navLink:hover{
    color: hsl(199, 100%, 33%);
    color: var(--PrimaryColor)
}

.wabtn {
    position:fixed;
    width:200px;
    height:40px;
    bottom:30px;
    right:50px;
    background-color:#0C9;
    color:#FFF;
    border-radius:5px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
    z-index: 10000;
}

@media screen and (max-width: 600px) {
    .wabtn {
        position:fixed;
        width:150px;
        height:30px;
        bottom:25px;
        right:25px;
        border-radius:5px;
    }
}

@media screen and (min-width: 912px){
    .openNavBar, .closeNavBar {
        display:none;
    }

    .header {
        padding: 1.5rem, 2.5rem;
    }

    .navBar .navLists {
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: row;
    }

    .navItem .navLink {
        color: hsl(240, 4%, 36%);
        color: var(--textColor);
        padding: 0 0.45rem;
        font-size:0.9rem;
        font-weight:500;
    }
    .link{
        color:hsl(240, 4%, 36%);
        color:var(--textColor);
    }
    .navLink:hover, .link:hover {
        color:hsl(199, 100%, 33%);
        color:var(--PrimaryColor);
    }

    .btn {
        margin-left:1rem; 
    }

}

@media screen and (min-width: 1100px) {
    .navItem {
        padding: 0 0.7rem;
    }
}

@media screen and (min-width: 1240px) {
    .header {
        padding: 1rem 1.5rem;
    }
}

:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 225);
}

.home {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  top: 50px;
}
.home .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(47, 106, 127);
  top: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
}
.home video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.home .homeContent {
  width: 100%;
  height: 100%;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
  z-index: 100;
}
.home .homeContent .textDiv {
  padding: 2rem 0;
}
.home .homeContent .textDiv .smallText {
  font-size: 15px;
  text-transform: uppercase;
}
.home .homeContent .textDiv .homeTitle {
  position: relative;
  font-size: 2rem;
}
.home .homeContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.home .homeContent .homeFooterIcons .icon {
  font-size: 45px;
  margin: 0 5px;
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
  font-weight: 600;
}
.home .homeContent .homeFooterIcons .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--PrimaryColor);
}

@media screen and (min-width: 500px) {
  .home .homeContent .textDiv .homeTitle {
    position: relative;
    width: 100%;
  }
}
@media screen and (min-width: 660px) {
  .home .homeContent .cardDiv {
    padding: 2rem 1rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .home .homeContent .textDiv .smallText {
    font-size: 25px;
  }
  .home .homeContent .textDiv .homeTitle {
    font-size: 3.5rem;
  }
}
@media screen and (min-width: 940px) {
  .home {
    height: 70vh;
  }
  .home .homeContent .textDiv .smallText {
    font-size: 30px;
  }
  .home .homeContent .textDiv .homeTitle {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1240px) {
  .home {
    height: 80vh;
  }
  .home .homeContent {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }
}/*# sourceMappingURL=home.css.map */
:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 225);
}

.footer {
  width: 100%;
  position: relative;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.footer .videoDiv {
  position: absolute;
  height: 100%;
  width: 100%;
}
.footer .videoDiv video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.footer .videoDiv::after {
  content: "";
  position: absolute;
  /* background: rgba(10, 84, 107, 0.307); */
  background: rgb(91, 181, 214);;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
}

/* .footer .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: magenta;
  top: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
} */

.footer .secContent {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 2rem initial;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  z-index: 100;
}
.footer .secContent .contactDiv {
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.footer .secContent .contactDiv .text small {
  font-size: 13px;
  font-weight: 400;
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
}
.footer .secContent .contactDiv .text h2 {
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
  font-size: 1.8rem;
}
.footer .secContent .contactDiv .inputDiv {
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
}
.footer .secContent .contactDiv .inputDiv input {
  width: 100%;
  /* margin-top: 1rem; */
  padding: 0.5rem 0.6rem;
  border-radius: 3rem;
  border: 1px solid hsl(0, 0%, 100%);
  border: 1px solid var(--whiteColor);
  outline: none;
  background: transparent;
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
}
.footer .secContent .contactDiv .inputDiv input::-webkit-input-placeholder {
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
  opacity: 0.5;
}
.footer .secContent .contactDiv .inputDiv input::placeholder {
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
  opacity: 0.5;
}
.footer .secContent .contactDiv .inputDiv .btn {
  padding-top: 0.5rem;
  width: 100%;
  justify-content: center;
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
  font-weight: 500;
}
.footer .secContent .contactDiv .inputDiv .btn .icon {
  font-size: 18px;
  color:white;
  margin-left:10px;
}
.footer .secContent .footerCard {
  position: relative;
  padding: 1rem 1rem 4rem;
  grid-gap: 2rem;
  gap: 2rem;
  border-radius: 1rem;
  background: rgb(240, 240, 246);
  background: var(--bodyColor);
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.footer .secContent .footerCard .footerIntro {
  flex-basis: 50%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}
.footer .secContent .footerCard .logoDiv .logo {
  color: hsl(201, 33%, 16%);
  color: var(--blackColor);
  font-weight: 600;
}
.footer .secContent .footerCard .logoDiv .logo .icon {
  font-size: 25px;
  color: hsl(199, 100%, 33%);
  color: var(--PrimaryColor);
  margin-right: 10px;
}
.footer .secContent .footerCard .footerParagraph {
  font-size: 13px;
  margin-left: 10px;
  color: hsl(240, 4%, 36%);
  color: var(--textColor);
  text-align: justify;
}
.footer .secContent .footerCard .footerSocials {
  margin-left: 10px;
  -moz-column-gap: 0.5rem;
       grid-column-gap: 0.5rem;
       -webkit-column-gap: 0.5rem;
               column-gap: 0.5rem;
}
.footer .secContent .footerCard .footerSocials .icon {
  color: hsl(201, 33%, 16%);
  color: var(--blackColor);
  font-size: 20px;
  margin-right: 0.5rem;
}
.footer .secContent .footerCard .footerSocials .icon:hover {
  color: hsl(199, 100%, 33%);
  color: var(--PrimaryColor);
}
.footer .secContent .footerCard .footerLinks {
  flex-basis: 25%;
  width: 200%;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}
.footer .secContent .footerCard .footerLinks .linkGroup .groupTitle {
  display: block;
  font-weight: 600;
  color: hsl(201, 33%, 16%);
  color: var(--blackColor);
  margin-bottom: 0.5rem;
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList {
  font-size: 15px;
  color: hsl(240, 4%, 36%);
  color: var(--textColor);
  transition: 0.1s ease-in-out;
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList .icon {
  font-size: 15px;
  color: rgb(190, 190, 190);
  color: var(--greyText);
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover {
  color: hsl(199, 100%, 33%);
  color: var(--PrimaryColor);
  -webkit-transform: translateX(7px);
          transform: translateX(7px);
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover .icon {
  color: hsl(187, 85%, 43%);
  color: var(--SecondaryColor);
}
.footer .secContent .footerCard .footerDiv {
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsl(187, 85%, 43%);
  background: var(--SecondaryColor);
  padding: 0.5rem 2rem;
}
.footer .secContent .footerCard .footerDiv small {
  font-size: 10px;
  color: hsl(0, 0%, 100%);
  color: var(--whiteColor);
}

@media screen and (min-width: 100px) {
  .footer .secContent .footerCard .footerLinks {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 1rem;
  }
  .footer .secContent .footerCard .footerDiv {
    justify-content: space-between;
    flex-direction: row;
  }
  .footer .secContent .footerCard .footerDiv small {
    font-size: 14px;
    color: hsl(0, 0%, 100%);
    color: var(--whiteColor);
  }
}
@media screen and (min-width: 500px) {
  .footer .secContent .footerCard .footerLinks {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1rem;
  }
  .footer .secContent .footerCard .footerDiv {
    justify-content: space-between;
    flex-direction: row;
  }
  .footer .secContent .footerCard .footerDiv small {
    font-size: 14px;
    color: hsl(0, 0%, 100%);
    color: var(--whiteColor);
  }
  .footer .secContent .contactDiv .inputDiv input {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .footer .secContent .contactDiv {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .secContent .contactDiv .inputDiv {
    width: -webkit-max-content;
    width: max-content;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .secContent .contactDiv input {
    width: 80%;
  }
  .footer .secContent .contactDiv .btn {
    width: -webkit-max-content;
    width: max-content;
  }
}

@media screen and (min-width: 840px) {
  .footer {
    padding: 5rem 0;
  }
  .footer .secContent .footerCard {
    flex-direction: row;
    width : 800px;
  }
  #padGroup1 {
    width: 120px;
    margin-top: 2rem;
    margin-bottom: 0rem;
  }
  #padGroup2 {
    width: 150px;
    margin-top: 2rem;
    margin-bottom: 0rem;
  }
  .footer .secContent .footerCard .footerLinks {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1rem;
  }
  .footer .secContent .contactDiv .inputDiv input {
    margin-top: 0rem;
  }
}

@media screen and (min-width: 1024px) {
  .footer .secContent .footerCard {
    padding: 1rem 1rem;
    padding-bottom: 2.5rem;
    width : 1000px;
  }
  .footer .secContent .footerCard .footerIntro .footerParagraph {
    font-size: 15px;
    margin-left: 20px;
    color: hsl(240, 4%, 36%);
    color: var(--textColor);
  }
  .footer .secContent .footerCard .footerIntro .footerSocials {
    -moz-column-gap: 0.5rem;
         grid-column-gap: 0.5rem;
         -webkit-column-gap: 0.5rem;
                 column-gap: 0.5rem;
    margin-left: 20px;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon {
    color: hsl(240, 4%, 36%);
    color: var(--textColor);
    font-size: 25px;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon:hover {
    color: hsl(199, 100%, 33%);
    color: var(--PrimaryColor);
  }
  .footer .secContent .footerCard .footerLinks .linkGroup .footerList {
    font-size: 16px;
    padding: 5px 0;
  }
  #padGroup1 {
    width: 170px;
  }
  #padGroup2 {
    width: 250px;
  }
}

@media screen and (min-width: 1500px) {
  .footer .secContent .footerCard {
    width : 60vw;
  }
}/*# sourceMappingURL=footer.css.map */
.package {
  width: 100%;
}

.title {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  color: var(--textColor);
  margin-top: 4.5rem;
  margin-bottom: 2rem;
  z-index: 2;
}


.trip {
  padding-top:105px;
  flex: 9 1;
}

.tripMain {
  padding: 20px;
  display:flex;
  flex-direction: column;
}

.tripImg {
  width: 100%;
  height: 362px;
  border-radius: 5px;
  object-fit: cover;
}

.tripDest {
  text-align: center;
  margin: 36px 10px 0 10px;
  color:var(--PrimaryColor);
  text-shadow: 1px 1px black;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
}

.tripState {
  text-align: center;
  margin: 5px 10px;
  font-size: 25px;
  color: var(--PrimaryColor);
  font-family: "Poppins", sans-serif;
}

.tripInfo {
  padding: 1.5rem 3rem;
}

.tripMeta {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  color: var(--textColor);
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}

.tripSeason{
font-weight: 600;
color:var(--PrimaryColor);
}

.tripDesc {
  color: var(--textColor);
  font-size: 18px;
  padding:10px 0;
  line-height: 28px;
}

.tripDesc .intro, .details, .points {
  padding:0 0 1rem 0;
  text-align: justify;
}

.tripDesc .dayTitle{
  color:var(--PrimaryColor);
  font-size:26px;
  line-height: 40px;
  margin-top:2.5rem;
  margin-bottom: 0.3rem;
  font-weight:bold;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.tripDesc .dayTitle:hover {
  cursor: pointer;
  box-shadow: -2.5px 2.5px 2.5px gray;
}

.dayTitle .headX
{
  padding-left:1rem;
}

.points {
  list-style-type: square;
}

.point-list {
  padding-left: 1.5rem;
}

.expand {
  font-size:2rem;
  margin:4px;
}

.expand:hover {
  color:var(--SecondaryColor)
}

@media screen and (max-width:550px) { 
  .point-list {
    padding-left: 0rem;
  }
}

@media screen and (max-width:800px){

  .tripDest{
    font-size: 35px;
  }
  .tripInfo {
    padding: 1rem 2.5rem;
  }
  .tripMeta {
    font-size: 20px;
  }
  .tripDesc {
    font-size:13px;
    line-height: 24px;
  }

  .tripDesc .dayTitle {
    font-size: 21px;
    padding:10px 0;
    line-height: 30px;
    margin-top: 2rem;
    margin-bottom: 0;
  }
} 

@media screen and (max-width:570px)
{
  .tripMeta {
    flex-direction: column;
    font-size: 20px;
  }
}

/* owl carusel */
/* .owl-buttons {
display: none;
}
.owl-carousel:hover .owl-buttons {
display: block;
}

.owl-item {
text-align: center;
}

.owl-theme .owl-controls .owl-buttons div {
background: transparent;
color: #869791;
font-size: 40px;
line-height: 300px;
margin: 0;
padding: 0 60px;
position: absolute;
top: 0;
}
.owl-theme .owl-controls .owl-buttons .owl-prev {
left: 0;
padding-left: 20px;
}
.owl-theme .owl-controls .owl-buttons .owl-next {
right: 0;
padding-right: 20px;
} */
:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 225);
}

body, input, textarea {
  font-family: "Poppins", sans-serif;
}

.contact-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: hsl(199, 100%, 33%);
  background-color: var(--PrimaryColor);
  overflow: hidden;
}

.contact {
  padding: 2rem;
  padding-top: 10rem;
}

.form {
  width: 100%;
  max-width: 900px;
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
}

.contact-form {
  background-color: hsl(187, 85%, 43%);
  background-color: var(--SecondaryColor);
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #049496);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: hsl(187, 85%, 43%);
  background-color: var(--SecondaryColor);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.heading {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}
.input::-webkit-input-placeholder{
  color:white;
  font-size: 0.8rem;
}
.input::placeholder{
  color:white;
  font-size: 0.8rem;
}
textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.butn {
  padding: 0.6rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #1abc9c;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  -webkit-transform: 0.3s;
          transform: 0.3s;
  margin: 0;
}

.butn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;

  /* background-color: red; */
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  height: 5px;
  opacity: 0;
  transition: 0.3s;
  background-color: hsl(187, 85%, 43%);
  background-color: var(--SecondaryColor);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.3rem;
  position: relative;
}

.contact-info .title {
  color: #1abc9c;
}

.conicon {
  width: 28px;
  height: 20px;
  margin-right: 0.7rem;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.75rem;
}

.social-media a{
  color: hsl(201, 33%, 16%);
  color: var(--blackColor);
  font-size: 35px;
  margin-right: 0.5rem;
}

.social-media a:hover{
  color: hsl(199, 100%, 33%);
  color: var(--PrimaryColor);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.form .text {
  text-align: justify;
}

@media (max-width: 750px) {
  .form {
    grid-template-columns: 1fr;
  }

  .title {
    padding-top:0;
  }
  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 75vw;
  }

  .text {
    margin: 1rem 0 1.5rem 0;;
  }

  .social-media {
    padding: 1.5rem 0 0;
  }
}

@media (max-width: 480px) {
  /* .container {
    padding: 1.5rem;
  } */

  .contact-info:before {
    display: none;
  }

  form, .contact-info {
    padding: 1.7rem 1.6rem;
  }
}

.admincover{
   width: 100%;


}

/* h2{
    position: relative;

    margin-top: 10rem;
} */
.writeSubmit {
    /* position: relative; */
    /* right: 7%; */
    color: white;
    background-color: teal;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width:8rem;
  }
  
