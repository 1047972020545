.trip {
  padding-top:105px;
  flex: 9;
}

.tripMain {
  padding: 20px;
  display:flex;
  flex-direction: column;
}

.tripImg {
  width: 100%;
  height: 362px;
  border-radius: 5px;
  object-fit: cover;
}

.tripDest {
  text-align: center;
  margin: 36px 10px 0 10px;
  color:var(--PrimaryColor);
  text-shadow: 1px 1px black;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
}

.tripState {
  text-align: center;
  margin: 5px 10px;
  font-size: 25px;
  color: var(--PrimaryColor);
  font-family: "Poppins", sans-serif;
}

.tripInfo {
  padding: 1.5rem 3rem;
}

.tripMeta {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  color: var(--textColor);
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}

.tripSeason{
font-weight: 600;
color:var(--PrimaryColor);
}

.tripDesc {
  color: var(--textColor);
  font-size: 18px;
  padding:10px 0;
  line-height: 28px;
}

.tripDesc .intro, .details, .points {
  padding:0 0 1rem 0;
  text-align: justify;
}

.tripDesc .dayTitle{
  color:var(--PrimaryColor);
  font-size:26px;
  line-height: 40px;
  margin-top:2.5rem;
  margin-bottom: 0.3rem;
  font-weight:bold;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.tripDesc .dayTitle:hover {
  cursor: pointer;
  box-shadow: -2.5px 2.5px 2.5px gray;
}

.dayTitle .headX
{
  padding-left:1rem;
}

.points {
  list-style-type: square;
}

.point-list {
  padding-left: 1.5rem;
}

.expand {
  font-size:2rem;
  margin:4px;
}

.expand:hover {
  color:var(--SecondaryColor)
}

@media screen and (max-width:550px) { 
  .point-list {
    padding-left: 0rem;
  }
}

@media screen and (max-width:800px){

  .tripDest{
    font-size: 35px;
  }
  .tripInfo {
    padding: 1rem 2.5rem;
  }
  .tripMeta {
    font-size: 20px;
  }
  .tripDesc {
    font-size:13px;
    line-height: 24px;
  }

  .tripDesc .dayTitle {
    font-size: 21px;
    padding:10px 0;
    line-height: 30px;
    margin-top: 2rem;
    margin-bottom: 0;
  }
} 

@media screen and (max-width:570px)
{
  .tripMeta {
    flex-direction: column;
    font-size: 20px;
  }
}

/* owl carusel */
/* .owl-buttons {
display: none;
}
.owl-carousel:hover .owl-buttons {
display: block;
}

.owl-item {
text-align: center;
}

.owl-theme .owl-controls .owl-buttons div {
background: transparent;
color: #869791;
font-size: 40px;
line-height: 300px;
margin: 0;
padding: 0 60px;
position: absolute;
top: 0;
}
.owl-theme .owl-controls .owl-buttons .owl-prev {
left: 0;
padding-left: 20px;
}
.owl-theme .owl-controls .owl-buttons .owl-next {
right: 0;
padding-right: 20px;
} */