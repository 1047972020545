.secContent {
  justify-content: center;
  gap: 1.5rem;
}
.Card {
  height: 100%;
  display: grid;
  row-gap: 10px;
  border-radius: 10px;
  align-items: center;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  overflow: hidden;
}
.Card:hover {
  background: var(--cardHover);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.imageDiv {
  height: 180px;
  width: 100%;
  overflow: hidden;
}
.imageDiv img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 2s ease;
}
.imageDiv:hover img {
  transform: scale(1.1);
}
.cardInfo {
  padding: 1rem;
}

.titles {
  margin-left: 12px;
}

.dest {
  color: var(--blackColor);
  font-size: 1.5rem;
  font-weight: 800;
  height:4.6rem;
}
.continent {
  gap: 0.5rem;
}
.icon {
  color: var(--textColor);
  font-size: 18px;
}
.state, .season {
  color: var(--textColor);
  font-weight: 400;
  font-size: 14px;
}

.season {
  padding-right: 20px;
}
.smallTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardInfo .meta {
  width: 100%;
  /* flex-direction:column; */
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0;
  border-top: 1.5px solid var(--greyText);
  border-bottom: 1.5px solid var(--greyText);
}
.meta .grade {
  color: var(--textColor);
  max-width: 100px;
  line-height: 20px;
  display: flex;
  justify-content: center;
}
.meta .grade small {
  background: var(--gradientColor);
  border-radius: 1rem;
  color: var(--whiteColor);
  padding: 1px 10px;
  font-size: 10px;
}
.meta .duration {
  font-size: 1.25rem;
  color: var(--blackColor);
}
.meta .price {
  font-size: 1.25rem;
  color: var(--blackColor);
}
 .desc {
  font-size: 13.5px;
  color: var(--textColor);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding-left: 15px;
  
}


 .desc p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

 .desc .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: space-between;
  gap: 0.5rem;
  margin-left: -2px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.desc .btn .icon {
  align-self: center;
  font-size: 18px;
  color: var(--whiteColor);
}

@media screen and (min-width: 625px) {
  .secContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .secContent .Card {
    height: auto;
  }
}

@media screen and (min-width: 1000px) {
  .secContent {
    grid-template-columns: repeat(3, 1fr);
  }
  .secContent .Card {
    height: auto;
  }
} 

@media screen and (max-width: 1000px) {
  .meta {
    flex-direction: column;
  }

  .desc {
    text-align: center;
  }

  .desc .btn {
    margin: auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 320px) {
  .cardInfo > .titles > .dest {
    height: 6.5rem;
  }
}