:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 225);
}

.header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--whiteColorDeam);
    width: 100%;
    padding: 1rem;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
}

.logo{
    color: black;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.logo .icon{
    font-size: 25px;
    color: var(--PrimaryColor);
    transform: translate(5px);
    margin:1rem;
    height: 40px;
    width: auto;
}

a .btn {
    color:var(--whiteColor);
    font-weight: 500;
}

@media screen and (max-width: 400px) {
    .logo {
        font-size: 10px;
    }
}

@media screen and (max-width: 911px) {
    .navBar{
        position:absolute;
        background:white;
        height: max-content;
        width:50%;
        padding: 1rem;
        border-radius: 1rem;
        display: none;
        top: 6rem;
        left: 50%;
        /* transform: translate(-50%); */
        z-index:2000;
        box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
        transition: .1s ease-in-out;
    }

    .activeNavBar{
        display:block;
        /* top:6rem; */
    }

    .navLists{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: auto;
        padding: .5rem 0;
    }
    .navItem{
        padding: .5rem 0;
    }

    .closeNavBar{
        display: block;
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        color: var(--PrimaryColor);
    }
    .closeNavBar:hover{
        color: var(--SecondaryColor);
    }

    .openNavBar .icon{
        display: block;
        font-size: 25px;
        color: black;
    }

    .openNavBar .icon:hover{
        color: var(--PrimaryColor);
    }

    
.btn{
    background-color: var(--PrimaryColor);
    margin-top: 1rem;
}

}

.navLink{
    font-size: .9rem;
    font-weight: 600;
    color: black;
}
.navLink:hover{
    color: var(--PrimaryColor)
}

.wabtn {
    position:fixed;
    width:200px;
    height:40px;
    bottom:30px;
    right:50px;
    background-color:#0C9;
    color:#FFF;
    border-radius:5px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
    z-index: 10000;
}

@media screen and (max-width: 600px) {
    .wabtn {
        position:fixed;
        width:150px;
        height:30px;
        bottom:25px;
        right:25px;
        border-radius:5px;
    }
}

@media screen and (min-width: 912px){
    .openNavBar, .closeNavBar {
        display:none;
    }

    .header {
        padding: 1.5rem, 2.5rem;
    }

    .navBar .navLists {
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: row;
    }

    .navItem .navLink {
        color: var(--textColor);
        padding: 0 0.45rem;
        font-size:0.9rem;
        font-weight:500;
    }
    .link{
        color:var(--textColor);
    }
    .navLink:hover, .link:hover {
        color:var(--PrimaryColor);
    }

    .btn {
        margin-left:1rem; 
    }

}

@media screen and (min-width: 1100px) {
    .navItem {
        padding: 0 0.7rem;
    }
}

@media screen and (min-width: 1240px) {
    .header {
        padding: 1rem 1.5rem;
    }
}
