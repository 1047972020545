@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

*{
    padding:0;
    margin:0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 225);
}
a{
    text-decoration: none;
}

li{
    list-style: none;
}
.section{
    padding: 4rem 0 2rem; 
}

.container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon{
    font-size:2rem;
    cursor: pointer;
}

.flex{
    display: flex;
    align-items:center;
}

.grid{
    display: grid;
    align-items: center;
}

.btn{
    padding: .6rem 1.5rem;
    background: var(--gradientColor);
    border-radius: 3rem;
    border: none;
    outline: none;
    cursor: pointer
}

.btn a{
    color: var(--whiteColor);
    font-weight: 500;
}

.btn:hover{
    background: var(--SecondaryColor);
}

img, video{
    width: 100%;
    height: auto;
}

input{
    font-size: 100%;
}

body{
    background: var(--bodyColor);
}

.blocker {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ' ';
    background: rgba(0,0,0,.5);
  }