.main {
  width: 100%;
}

.titler {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: var(--textColor);
  margin: 1rem 0;
  z-index: 2;
}

@media screen and (max-width: 500px) {
  .titler {
    margin-top: 2rem;
  }
}